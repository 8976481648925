import { Paragraph } from '@/components/common/MarkUp';
import { Surface } from '@/components/common/Surface';
import { Col, Row } from '@/components/layout/Grid';
import { Section } from '@/components/layout/Section';
import { Stack } from '@/components/layout/Stack';
import { getTextColour } from '@/lib/colourUtils';

import type { Sizes } from '@/components/layout/Grid';
import type { SanityStatsType } from '@/types/sanity';

export interface StatsProps extends Omit<SanityStatsType, '_type'> {
  sizes?: Sizes;
}

export const StatsComponent = ({ statistics, sizes }: StatsProps) => {
  return (
    <Row>
      {statistics.map((stat) => {
        const backgroundColourToken = stat?.surfaceColour?.token;
        const statFigureColour =
          backgroundColourToken === '--surface-primary-subtle' ||
          !backgroundColourToken
            ? '--text-strong'
            : getTextColour(backgroundColourToken);
        const tabletLayout = statistics.length === 2 ? 6 : 12;
        return (
          <Col
            key={stat._key}
            sizes={
              sizes || {
                md: tabletLayout,
                lg: 4,
              }
            }
            style={{ textAlign: stat?.alignment }}
          >
            <Surface
              surfaceColour={backgroundColourToken}
              padding={
                backgroundColourToken ? '--spacing-large' : '--spacing-x-small'
              }
              borderRadius="--radius-m"
            >
              <Stack spacing="--spacing-small">
                <Paragraph
                  size="display-sm"
                  style={{ color: `var(${statFigureColour})` }}
                >
                  {stat.figure}
                </Paragraph>
                <Paragraph size="heading-md">{stat.label}</Paragraph>
                {stat?.description && (
                  <Paragraph size="subheading-sm">{stat.description}</Paragraph>
                )}
              </Stack>
            </Surface>
          </Col>
        );
      })}
    </Row>
  );
};

export const Stats = ({ statistics, sizes, backgroundColour }: StatsProps) => {
  return (
    <Section
      backgroundColour={backgroundColour?.token}
      verticalPadding
      className="stats"
    >
      <StatsComponent statistics={statistics} sizes={sizes}></StatsComponent>
    </Section>
  );
};
